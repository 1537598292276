<template>
    <div class="contact-form">
        <div class="contact-form-body">
            <div class="contact-form-item m-full">
                <div class="contact-form-item-label">
                    <span class="required">*</span>
                    {{ i18nPage.region }}
                </div>
                <div class="contact-form-item-value">
                    <v-select :reduce="i => i.code" :clearable="false" v-model="country" class="v-select" :options="country_list">
                        <template #no-options>
                            <div style="padding: 20px 0;">{{ $t('nodata') }}</div>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <svg v-bind="attributes" class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
                                <path fill-rule="nonzero" d="M4.5 6L0 0h9z" fill="#000" />
                            </svg>
                        </template>
                    </v-select>
                    <input type="hidden" v-model="country" v-validate="'required'" :data-vv-scope="scope" data-vv-name="country" :data-vv-as="i18nPage.country" />
                    <span class="error" v-show="vee_errors.has(`${scope}.country`)">{{ i18nPage.regionEmpty }}</span>
                </div>
            </div>

            <div class="contact-form-item">
                <div class="contact-form-item-label">
                    <span class="required">*</span>
                    {{ i18nPage.name }}
                </div>
                <div class="contact-form-item-value">
                    <input class="ipt" v-model="name" type="text" :data-vv-scope="scope" data-vv-name="name" :data-vv-as="i18nPage.name" v-validate="'required|max:50'" />
                    <span class="error" v-show="vee_errors.first(`${scope}.name`)">{{ vee_errors.first(`${scope}.name`) }}</span>
                </div>
            </div>

            <div class="contact-form-item">
                <div class="contact-form-item-label">
                    <span class="required">*</span>
                    {{ i18nPage.companyName }}
                </div>
                <div class="contact-form-item-value">
                    <input class="ipt" v-model="company_name" type="text" :data-vv-scope="scope" :data-vv-as="i18nPage.companyName" v-validate="'required|max:50'" data-vv-name="company_name" />
                    <span class="error" v-show="vee_errors.first(`${scope}.company_name`)">{{ vee_errors.first(`${scope}.company_name`) }}</span>
                </div>
            </div>

            <div class="contact-form-item m-full">
                <div class="contact-form-item-label">
                    <span class="required">*</span>
                    {{ i18nPage.businessType }}
                </div>
                <div class="contact-form-item-value" :class="{ other: company_type === 'other' }">
                    <v-select :reduce="i => i.code" :clearable="false" v-model="company_type" class="v-select" :options="types">
                        <template #no-options>
                            <div style="padding: 20px 0;">{{ $t('nodata') }}</div>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <svg v-bind="attributes" class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
                                <path fill-rule="nonzero" d="M4.5 6L0 0h9z" fill="#000" />
                            </svg>
                        </template>
                    </v-select>
                    <input v-show="company_type === 'other'" v-model="company_type_other" type="text" class="ipt" :data-vv-scope="scope" :data-vv-as="i18nPage.businessType" v-validate="company_type === 'other' ? 'required|max:50' : ''" data-vv-name="company_type_other" />
                    <input type="hidden" v-if="company_type !== 'other'" v-model="company_type" v-validate="'required'" :data-vv-scope="scope" data-vv-name="company_type" :data-vv-as="i18nPage.businessType" />
                    <span class="error" v-show="vee_errors.first(`${scope}.company_type`)">{{ vee_errors.first(`${scope}.company_type`) }}</span>
                    <span class="error" v-if="vee_errors.first(`${scope}.company_type_other`)">{{ vee_errors.first(`${scope}.company_type_other`) }}</span>
                </div>
            </div>

            <div class="contact-form-item">
                <div class="contact-form-item-label">
                    <span class="required">*</span>
                    {{ i18nPage.emailAddress }}
                </div>
                <div class="contact-form-item-value">
                    <input class="ipt" v-model="email" type="text" :data-vv-as="i18nPage.emailAddress" v-validate="`required|email|max:50`" :data-vv-scope="scope" data-vv-name="email" />
                    <span class="error" v-show="vee_errors.first(`${scope}.email`)">{{ vee_errors.first(`${scope}.email`) }}</span>
                </div>
            </div>

            <div class="contact-form-item">
                <div class="contact-form-item-label">
                    <span class="required">*</span>
                    {{ i18nPage.phoneNumber }}
                </div>
                <div class="contact-form-item-value">
                    <input class="ipt" v-model="phone" type="text" :data-vv-as="i18nPage.phoneNumber" v-validate="`required|phone`" :data-vv-scope="scope" data-vv-name="phone" />
                    <span class="error" v-show="vee_errors.first(`${scope}.phone`)">{{ vee_errors.first(`${scope}.phone`) }}</span>
                </div>
            </div>

            <div class="contact-form-item m-full">
                <div class="contact-form-item-label">
                    {{ i18nPage.hearfrom }}
                </div>
                <div class="contact-form-item-value">
                    <v-select multiple :reduce="i => i.code" :clearable="true" v-model="from_where_know" class="v-select" :options="fromItems">
                        <template #no-options>
                            <div style="padding: 20px 0;">{{ $t('nodata') }}</div>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <svg v-bind="attributes" class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
                                <path fill-rule="nonzero" d="M4.5 6L0 0h9z" fill="#000" />
                            </svg>
                        </template>
                    </v-select>
                </div>
            </div>

            <div class="contact-form-item m-full">
                <div class="contact-form-item-label">
                    <span class="required">*</span>
                    {{ i18nPage.interest }}
                </div>
                <div class="contact-form-item-value">
                    <v-select multiple :reduce="i => i.code" :clearable="true" v-model="interest" class="v-select" :options="interestItems">
                        <template #no-options>
                            <div style="padding: 20px 0;">{{ $t('nodata') }}</div>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <svg v-bind="attributes" class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
                                <path fill-rule="nonzero" d="M4.5 6L0 0h9z" fill="#000" />
                            </svg>
                        </template>
                    </v-select>
                    <input type="hidden" v-model="interest" v-validate="'required'" :data-vv-scope="scope" data-vv-name="interest" :data-vv-as="i18nPage.interest" />
                    <span class="error" v-show="vee_errors.first(`${scope}.interest`)">{{ vee_errors.first(`${scope}.interest`) }}</span>
                </div>
            </div>

            <div class="contact-form-item full m-full" v-if="isShowDescription">
                <div class="contact-form-item-label">
                    {{ i18nPage.description }}
                </div>
                <div class="contact-form-item-value">
                    <textarea class="textarea" :placeholder="i18nPage.descriptionPH" v-model="remark" v-validate="`max:2000`" :data-vv-scope="scope" data-vv-name="remark" :data-vv-as="i18nPage.description"></textarea>
                    <span class="error" v-show="vee_errors.has(`${scope}.remark`)">{{ vee_errors.first(`${scope}.remark`) }}</span>
                </div>
            </div>
        </div>
        <div class="contact-form-agree" :class="{ 'margin-top-0': !isShowDescription }" @click="agree = !agree">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="icon">
                <g stroke="none" stroke-width="1" fill="none" v-if="agree">
                    <rect stroke="#1FC8D1" stroke-width="1.2" x="0.6" y="0.6" width="12.8" height="12.8" rx="6.4"></rect>
                    <rect fill="#1FC8D1" x="3" y="3" width="8" height="8" rx="4"></rect>
                </g>
                <g stroke="#D9D9D9" stroke-width="1" fill="none" v-else>
                    <rect x="0.6" y="0.6" width="12.8" height="12.8" rx="6.4"></rect>
                </g>
            </svg>
            <span class="required">*</span>
            <i18n class="text" path="contactPage.top.agreement" tag="div">
                <a @click.stop :href="staticsPage.service" target="_blank" place="0" class="link">{{ i18nPage.service }}</a>
                <a @click.stop :href="staticsPage.agreement" target="_blank" place="1" class="link">{{ i18nPage.privacy }}</a>
            </i18n>
        </div>
        <div class="contact-form-foot">
            <a href="javascript:;" class="poppins contact-form-foot-submit" @click="onSubmit">{{ i18nPage.submit }}</a>
        </div>
    </div>
</template>

<script>
import qs from 'qs';
import { mapState } from 'vuex';
import http from '@utils/http';
import ADDRESS from '@utils/address.json';
import { getCookieItem } from '@utils/common';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default {
    inject: ['$validator'],
    components: {
        vSelect,
    },
    props: {
        isShowDescription: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            scope: 'contact',
            api: `${process.env.VUE_APP_CONTACT_API}`,
            isSubmiting: false, // 防止暴力点击
            agree: false,
            name: '', // 名字
            company_name: '', // 公司名称
            country: '', // 地区
            company_type: '', // 公司类型
            company_type_other: '', // 其他类型
            email: '', // 邮箱
            phone: '', // 电话号码
            from_where_know: [],
            remark: '',
            interest: [],
        };
    },
    computed: {
        ...mapState({
            lang: state => state.route.params.lang,
        }),
        i18nPage() {
            return this.$t('contactPage.top');
        },
        staticsPage() {
            const domain = 'https://h5.meitu.com/eve';
            const { lang } = this;
            const country_code =
                {
                    zh: 100000,
                    en: 'default',
                    ko: 14100000,
                    ja: 13920000,
                    tw: 158710000,
                }[lang] || '';
            return {
                service: `${domain}/statics/service/index.html?country_code=${country_code}`,
                agreement: `${domain}/statics/agreement/index.html?country_code=${country_code}`,
            };
        },
        address_map() {
            let lang = this.lang;
            if (lang === 'zh' || lang === 'en' || lang === 'tw') {
                return ADDRESS[lang];
            } else {
                return ADDRESS['en'];
            }
        },
        country_list() {
            // 对地区使用sort进行排序
            return Object.keys(this.address_map)
                .map(key => {
                    let item = this.address_map[key];

                    var data = {
                        index: item.index,
                        code: key,
                        label: item.country,
                    };
                    // 语言为日韩时，需要把对应国家放在第一个
                    if (this.lang === 'ja' && key === '13920000') {
                        data.index = 1;
                    }
                    if (this.lang === 'ko' && key === '14100000') {
                        data.index = 1;
                    }
                    return data;
                })
                .sort((a, b) => {
                    // 排序规则： 有index的按index排序。否则按首字母排序
                    if (a.index && b.index) {
                        return a.index - b.index;
                    } else if (a.index) {
                        return -1;
                    } else if (b.index) {
                        return 1;
                    } else {
                        if (this.lang === 'zh' || this.lang === 'tw') {
                            return a.label.localeCompare(b.label, 'zh');
                        } else {
                            if (a.label < b.label) {
                                return -1;
                            }
                            if (a.label > b.label) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                });
        },
        types() {
            let { i18nPage } = this;
            return [
                {
                    code: 1,
                    label: i18nPage.type1,
                    text: 'Beauty Brand',
                },
                {
                    code: 2,
                    label: i18nPage.type2,
                    name: 'Beauty Salon',
                },
                {
                    code: 3,
                    label: i18nPage.type3,
                    name: 'Medical Beauty Clinic',
                },
                {
                    code: 4,
                    label: i18nPage.type4,
                    name: 'Reseller/Channel',
                },
                {
                    code: 5,
                    label: i18nPage.type5,
                    name: 'Supplier',
                },
                {
                    code: 'other',
                    label: i18nPage.other,
                },
            ];
        },
        fromItems() {
            let { i18nPage } = this;
            return [
                {
                    code: 101,
                    label: i18nPage.from1,
                    name: 'WeChat',
                },
                {
                    code: 102,
                    label: i18nPage.from2,
                    name: 'News',
                },
                {
                    code: 103,
                    label: i18nPage.from3,
                    name: 'Alibaba',
                },
                {
                    code: 104,
                    label: i18nPage.from4,
                    name: 'Google',
                },
                {
                    code: 105,
                    label: i18nPage.from5,
                    name: 'Advertisement',
                },
                {
                    code: 106,
                    label: i18nPage.from6,
                    name: 'Offline Event',
                },
                {
                    code: 107,
                    label: i18nPage.from7,
                    name: 'Facebook',
                },
                {
                    code: 108,
                    label: i18nPage.from8,
                    name: 'LinkedIn',
                },
                {
                    code: 109,
                    label: i18nPage.from9,
                    name: 'Product Manual',
                },
                {
                    code: 110,
                    label: i18nPage.from10,
                    name: 'Friend/Family Referral',
                },
                {
                    code: 7,
                    label: i18nPage.other,
                    name: 'Other',
                },
            ];
        },
        interestItems() {
            let { i18nPage } = this;
            return [
                {
                    code: 11,
                    label: i18nPage.interest1,
                    name: 'Online - App/Website/WeChat Mini Program',
                },
                {
                    code: 12,
                    label: i18nPage.interest2,
                    name: 'Offline - Hardware (MeituEve/MeituKey)',
                },
            ];
        },
    },
    methods: {
        validateAll() {
            return this.$validator.validateAll(this.scope).then(value => {
                if (this.vee_errors.any()) {
                    return Promise.reject(this.vee_errors);
                }
                return Promise.resolve(value);
            });
        },
        getSelectName(type, value) {
            if (!value) return '';
            var result = '';
            this[type].forEach(item => {
                if (item.code === value) {
                    result = item.label;
                }
            });
            return result;
        },
        onSubmit() {
            this.$emit('send');
            this.validateAll().then(() => {
                if (!this.agree) {
                    this.$toast.center(this.i18nPage.agreementError);
                    return;
                }
                let company_type = this.getSelectName('types', this.company_type);
                let source = [];
                this.from_where_know.forEach(item => {
                    source.push(this.getSelectName('fromItems', item));
                });
                let product_interest = [];
                this.interest.forEach(item => {
                    product_interest.push(this.getSelectName('interestItems', item));
                });
                let { lang } = this;
                let url = process.env.VUE_APP_CONTACT_API_EN;
                if (lang === 'ko') {
                    url = process.env.VUE_APP_CONTACT_API_KO;
                }
                if (lang === 'ja') {
                    url = process.env.VUE_APP_CONTACT_API_JA;
                }
                if (lang === 'zh') {
                    url = process.env.VUE_APP_CONTACT_API_ZH;
                }
                let data = {
                    page_url: location.href,
                    _ga: getCookieItem('_ga') || '',
                    _gid: getCookieItem('_gid') || '',
                    url,
                    region: this.getSelectName('country_list', this.country),
                    email: this.email,
                    name: this.name,
                    company: this.company_name,
                    company_type,
                    company_type_other: this.company_type_other,
                    phone: this.phone,
                    product_interest: product_interest.join(','),
                    remark: this.remark || '',
                    source: source.join(','),
                };
                console.log(data);
                let api = `${process.env.VUE_APP_API_HOST}h5/proxy_post`;
                if (this.isSubmiting) return;
                this.isSubmiting = true;
                http.post(api, qs.stringify(data))
                    .then(() => {
                        this.$emit('success', data);
                        this.$nextTick(() => {
                            this.isSubmiting = false;
                        });
                    })
                    .catch(error => {
                        this.$emit('error');
                        console.error(error);
                        this.isSubmiting = false;
                    });
            });
        },
    },
	mounted() {
		this.remark = this.$route.query.adremark||"";
	}

};
</script>

<style lang="scss">
.contact-form {
    .required {
        color: #FF1514;
    }
    .v-select {
        background: #fff;
        .vs__selected {
            font-size: 14px;
            font-weight: 500;
        }
        .vs__dropdown-toggle {
            min-height: 35px;
            box-sizing: border-box;
            border-color: rgba(0, 0, 0, 0.15);
        }
        .vs__dropdown-menu {
            border-color: #000;
        }
        &.vs--open {
            .vs__dropdown-toggle {
                border-color: #000;
            }
        }
        &.vs--unsearchable {
            .vs__search {
                height: 0 !important;
                margin: 0 !important;
                border: 0 !important;
            }
        }
        .vs__dropdown-option {
            white-space: normal;
        }
    }
    @media only screen and (min-width: 769px) {
        &-body {
            padding: 60px 42px 0 42px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &-item {
            width: 258px;
            margin-bottom: 24px;
            &:last-of-type {
                margin-bottom: 0;
            }
            &.full {
                width: 100%;

                .error {
                    top: 100px;
                }
            }

            &-label {
                font-size: 13px;
                line-height: 24px;
                font-weight: 600;
                margin-bottom: 2px;
            }

            &-value {
                position: relative;
                &.other {
                    display: flex;
                    .v-select {
                        width: 140px;
                        margin-right: 10px;
                        flex-shrink: 0;
                    }
                }

                .error {
                    font-size: 12px;
                    color: #FE480F;
                    position: absolute;
                    transform-origin: 0 center;
                    white-space: nowrap;
                    left: 0;
                    top: 37px;
                }
            }

            .ipt {
                height: 35px;
                font-size: 14px;
                line-height: 1.4;
                padding: 0 9px;
                color: #333;
                font-weight: 500;
                font-family: inherit;
            }
            .textarea {
                height: 98px;
                font-size: 14px;
                line-height: 1.2;
                padding: 9px 12px;
            }
            .ipt,
            .textarea {
                width: 100%;
                display: block;
                border-radius: 4px;
                border: solid 1px rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                &:focus {
                    border: 1px solid #000;
                }
            }
        }
        &-agree {
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            margin: 20px 42px 55px 42px;
            &.margin-top-0 {
                margin-top: 0;
            }
            .required {
                color: #FF1514;
                padding: 0 5px;
            }
            a {
                color: #00C8C8;
            }
        }
        &-foot {
            display: flex;
            flex-direction: column;
            align-items: center;
            &-submit {
                color: #000;
                font-size: 16px;
                line-height: normal;
                font-weight: 400;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                border: solid 1px #000;
                padding: 0 30px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item {
            width: 1.4rem;
            margin-bottom: 0.24rem;
            &:last-of-type {
                margin-bottom: 0;
            }
            &.m-full {
                width: 100% !important;
            }
            &.full {
                .error {
                    top: 100px;
                }
            }
            &-label {
                font-size: 0.12rem;
                line-height: 0.15rem;
                margin-bottom: 0.06rem;
                font-weight: 600;
            }
            &-value {
                position: relative;
                &.other {
                    display: flex;
                    .v-select {
                        width: 1.4rem;
                        margin-right: 0.22rem;
                        flex-shrink: 0;
                    }
                }
                .error {
                    font-size: 12px;
                    transform: scale(0.75);
                    color: #FE480F;
                    position: absolute;
                    transform-origin: 0 center;
                    white-space: nowrap;
                    left: 0;
                    top: 37px;
                }
            }
            .ipt {
                height: 35px;
                font-size: 14px;
                line-height: 1.4;
                padding: 0 9px;
                color: #333;
                font-weight: 500;
                font-family: inherit;
            }
            .textarea {
                height: 98px;
                font-size: 12px;
                line-height: 1.2;
                padding: 9px 12px;
            }
            .ipt,
            .textarea {
                width: 100%;
                display: block;
                border-radius: 4px;
                border: solid 1px rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                &:focus {
                    border: 1px solid #000;
                }
            }
        }

        &-agree {
            font-size: 0.1rem;
            line-height: 1.5;
            display: flex;
            align-items: center;
            margin-top: 0.1rem;
            margin-bottom: 0.33rem;
            .required {
                color: #FF1514;
                padding: 0 0.04rem;
            }
            a {
                color: #00C8C8;
            }
        }
        &-foot {
            display: flex;
            flex-direction: column;
            align-items: center;
            &-submit {
                color: #00C8C8;
                font-size: 0.16rem;
                line-height: normal;
                font-weight: 700;
                height: 0.36rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0.3rem;
                border: solid 2px #00C8C8;
                padding: 0 0.46rem;
            }
        }
    }
}
</style>
